import "@/components/common/Sentry";
import { StrictMode, Suspense } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import "apexcharts/dist/apexcharts.css";
import QueryProvider from "./providers/QueryProvider";
import BrowserRouterProvider from "./providers/BrowserRouterProvider";
import SentryErrorProvider from "./providers/SentryProvider";
import LoadingPage from "./components/common/loading/LoadingPage";

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <Suspense fallback={<LoadingPage progress={0} />}>
      <SentryErrorProvider>
        <QueryProvider>
          <BrowserRouterProvider />
        </QueryProvider>
      </SentryErrorProvider>
    </Suspense>
  </StrictMode>
);
