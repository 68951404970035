import React from "react";

interface LoginButtonProps {
  isValid: boolean;
  error?: Error | string | null;
  text?: string;
}

const LoginButton: React.FC<LoginButtonProps> = ({
  isValid = true,
  error = null,
  text = "로그인",
}) => {
  return (
    <>
      <button
        type="submit"
        disabled={!isValid}
        className={`self-stretch px-20 py-5 ${
          isValid ? "bg-blue-500 text-white" : "bg-white text-blue-500"
        } rounded-md outline outline-1 outline-offset-[-1px] outline-blue-500 text-base font-semibold font-['Pretendard'] w-full`}
      >
        {text}
      </button>
      {error && (
        <p className="text-red-500 text-sm mt-2">
          {error instanceof Error
            ? error.message
            : typeof error === "string"
            ? error
            : "로그인 중 오류가 발생했습니다."}
        </p>
      )}
    </>
  );
};

export default LoginButton;
