import PaperIcon from "@/assets/iconly-regular-light-paper.svg?react";
import SettingIcon from "@/assets/setting-icon.svg?react";
import { useStorage } from "@/hooks/common/useStorage";
import { Link } from "react-router-dom";

/**
 * 전체 데이터에서 진행중과 완료된 데이터의 갯수를 만드는 hook이 필요하다.
 * +사업자번호
 */

const InspectionSummary = () => {
  const { username, bussinessNumber, clearAll } = useStorage();
  return (
    <div className="px-7 py-12 bg-white rounded-2xl shadow-all border border-shadow inline-flex flex-col justify-start items-start gap-9">
      <div className="self-stretch flex flex-col justify-center items-start gap-3">
        <div className="self-stretch justify-start text-zinc-900 text-2xl font-semibold font-['Pretendard']">
          {username} 님
        </div>
        <div className="justify-start text-zinc-900 text-base font-normal font-['Pretendard']">
          사업자번호 {bussinessNumber}
        </div>
      </div>
      <div className="self-stretch px-5 py-4 bg-blue-50 rounded-md outline outline-1 outline-offset-[-1px] outline-blue-500 inline-flex justify-start items-center gap-6">
        <div className="p-2 bg-blue-300 rounded-full flex justify-start items-center gap-2.5 overflow-hidden">
          <PaperIcon className="w-6 h-6" />
        </div>
        <div className="flex justify-start items-center gap-6">
          <div className="w-9 inline-flex flex-col justify-start items-start gap-3">
            <div className="self-stretch text-center justify-start text-zinc-900 text-sm font-normal font-['Pretendard']">
              전체
            </div>
            <div className="self-stretch text-center justify-start text-gray-400 text-sm font-normal font-['Pretendard']">
              00개
            </div>
          </div>
          <div className="w-9 inline-flex flex-col justify-start items-start gap-3">
            <div className="self-stretch text-center justify-start text-zinc-900 text-sm font-normal font-['Pretendard'] whitespace-nowrap">
              진행중
            </div>
            <div className="self-stretch text-center justify-start text-gray-400 text-sm font-normal font-['Pretendard']">
              00개
            </div>
          </div>
          <div className="w-9 inline-flex flex-col justify-start items-start gap-3">
            <div className="self-stretch text-center justify-start text-zinc-900 text-sm font-normal font-['Pretendard']">
              완료
            </div>
            <div className="self-stretch text-center justify-start text-gray-400 text-sm font-normal font-['Pretendard']">
              00개
            </div>
          </div>
        </div>
      </div>
      <div className="w-72 flex flex-col justify-start items-start gap-4">
        <button
          onClick={clearAll}
          className="self-stretch px-20 py-5 bg-blue-500 rounded-md outline outline-1 outline-offset-[-1px] outline-blue-500 inline-flex justify-center items-center gap-2.5"
        >
          <div className="w-28 text-center justify-start text-white text-base font-semibold font-['Pretendard']">
            로그아웃
          </div>
        </button>
        <Link
          to={"/myinfo/update"}
          className="inline-flex justify-start items-center gap-2"
        >
          <SettingIcon className="w-5 h-5" />
          <div className="w-20 justify-start text-slate-500 text-sm font-medium font-['Pretendard']">
            내 정보 수정
          </div>
        </Link>
      </div>
    </div>
  );
};

export default InspectionSummary;
