import QnaDropDown from "@/components/qna/QnaDropDown";

const QnA = () => {
  const faqItems = [
    {
      question: "CancerVET의 원리는 무엇인가요?",
      answer:
        "증상이 나타나는 것은 이미 암의 발병이 진행되었음을 의미합니다. 종양이 체내에 성장한 시점은 수술과 치료에 투여되는 시간과 비용이 급격하게 증가합니다. 시간, 비용 및 반려동물의 건강을 위해서는 사전에 종양 유무를 확인할 수 있는 검사를 받아야 합니다. CancerVET은 혈액 내 미량의 탄화수소를 분석하여 종양의 유무 및 악성도를 평가할 수 있는 스크리닝 검사 방법입니다.",
    },
    {
      question: "암 검진은 몇 살부터 받아야 하나요?",
      answer:
        "암으로 확진된 3,452마리의 개를 대상으로 암 진단을 위한 적절한 시기를 조사하는 연구가 진행되었습니다. 다양한 품종과 나이를 대상으로 추적 조사를 수행한 결과, 일반적으로 6-7세부터 암 검진을 받는 것을 권장하고 있습니다. 다만 모든 종에 해당되는 것은 아니며, 암 진행이 상대적으로 빠르게 진행되는 품종과 환경이 존재합니다. 따라서 연구 결과에서는 조기 진단을 통한 적절한 대응과 치료를 위하여 4세부터 암 검진을 받는 것을 추천하고 있습니다.",
    },
    {
      question: "CancerVET 검사 주기는 어느 정도가 적당한가요?",
      answer:
        "'사람의 시간 1년이 개의 7년과 같다'는 말을 들어보셨나요? 0-1세 강아지는 사람의 15년, 1-2세 강아지는 사람의 9년과 같으며, 이후부터는 사람의 4-5년과 같다는 연구 결과가 밝혀졌습니다 [2]. 이를 계산하면 4세 이상의 강아지는 중년의 나이와 같다는 의미가 됩니다. 보통 사람은 1년에 한 번 정기검진을 받는데, 이를 강아지의 시간으로 환산하여 1년에 4회 검사하는 것을 권장하고 있습니다.",
    },
    {
      question: "양성 종양이나 악성 종양으로 예측된 경우에는 어떻게 하나요?",
      answer:
        "CancerVET은 혈액 기반 스크리닝 검사이기 때문에 어떤 부위에 종양이 존재하는지는 파악하기 어렵습니다. CancerVET의 검사 결과를 바탕으로 수의사와 상담을 진행하시는 것을 권장드립니다. 만약 검사 전 수술이나 약물 치료를 진행하셨다면 3-4주 이후 재검사를 진행하시는 것을 권장드립니다",
    },
  ];

  return (
    <div className="pt-6 pb-20">
      <div className="text-black text-2xl font-semibold font-['Pretendard'] ">
        자주 묻는 질문
      </div>
      <div className="mt-[10px] justify-start text-black text-base font-normal font-['Pretendard']">
        캔서벳을 더 잘 이용하실 수 있도록 미리 중요한 질문들을 준비했어요.
      </div>

      <div className="mt-[60px] space-y-4">
        {faqItems.map((item, index) => (
          <QnaDropDown
            key={index}
            question={item.question}
            answer={item.answer}
          />
        ))}
      </div>
    </div>
  );
};

export default QnA;
