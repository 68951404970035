import { Link, useLocation } from "react-router-dom";
import CancerVETLogo from "@/assets/CancerVet_logo.svg?react";
import { useRef, useState } from "react";
import Dropdown from "../home/Dropdown";
import { useStorage } from "@/hooks/common/useStorage";
import UserIcon from "@/assets/iconly-regular-light-profile.svg?react";
import GrayUserIcon from "@/assets/iconly-regular-dark-profile.svg?react";
import { useModal } from "@/hooks/common/useModal";

const GNB = () => {
  const location = useLocation();
  const { showModal, toggleModal, portalElement } = useModal();
  const userIconRef = useRef<HTMLDivElement>(null);
  const [dropdownPosition, setDropdownPosition] = useState({
    top: 0,
    right: 0,
  });
  const handleToggleDropdown = () => {
    if (userIconRef.current) {
      const rect = userIconRef.current.getBoundingClientRect();
      setDropdownPosition({
        top: rect.bottom + window.scrollY,
        right: window.innerWidth - rect.right,
      });
    }
    toggleModal();
  };

  const { pathname } = useLocation();
  const isAdmin = pathname.includes("admin");

  const { accessToken, adminToken, adminName, username } = useStorage();

  const isActive = (path: string) => location.pathname === path;

  // 메뉴 아이템 스타일 함수 - 호버와 액티브 상태 관리
  const getMenuItemStyles = (path: string) => {
    const active = isActive(path);
    return {
      textColorClass: active
        ? "text-blue-500"
        : "text-zinc-900 hover:text-blue-500",
    };
  };

  return (
    <header className="py-4 w-full z-[999] pointer-events-auto">
      <div className="w-full h-20 relative bg-white overflow-hidden flex items-center justify-between">
        {/* Logo Section */}
        <Link
          to="/"
          className="ml-5 md:ml-[5%] lg:ml-[10%] xl:ml-[15%] pointer-events-auto"
        >
          <div className="flex items-center">
            <CancerVETLogo
              className="h-9 w-28 mr-2"
              role="img"
              aria-label="CancerVet 로고"
            />
            {isAdmin ? <div className="font-bold">ADMIN</div> : ""}
          </div>
        </Link>

        {/* Navigation Links */}
        <div className="h-16 flex justify-center items-center absolute left-1/2 transform -translate-x-1/2 gap-4">
          <Link
            to="/introduce"
            className="py-3 mx-4 lg:mx-6 flex justify-center items-center gap-2.5 flex-wrap content-center group"
          >
            <div className="inline-flex flex-col justify-center items-center gap-2.5">
              <div
                className={`justify-start ${
                  getMenuItemStyles("/introduce").textColorClass
                } text-lg md:text-xl font-normal font-['Pretendard'] leading-loose transition-colors duration-200`}
              >
                소개
              </div>
              <div
                className={`self-stretch h-0.5 ${
                  isActive("/introduce")
                    ? "bg-blue-500"
                    : "bg-transparent group-hover:bg-blue-500"
                } transition-colors duration-200`}
              />
            </div>
          </Link>

          <Link
            to="/guide"
            className="py-3 mx-3 lg:mx-5 flex justify-center items-center gap-2.5 flex-wrap content-center group"
          >
            <div className="inline-flex flex-col justify-center items-center gap-2.5">
              <div
                className={`justify-start ${
                  getMenuItemStyles("/guide").textColorClass
                } text-lg md:text-xl font-normal font-['Pretendard'] leading-loose transition-colors duration-200`}
              >
                이용방법
              </div>
              <div
                className={`self-stretch h-0.5 ${
                  isActive("/guide")
                    ? "bg-blue-500"
                    : "bg-transparent group-hover:bg-blue-500"
                } transition-colors duration-200`}
              />
            </div>
          </Link>

          <Link
            to={isAdmin ? "/admin/dashboard" : "inspection"}
            className="py-3 mx-3 lg:mx-5 flex justify-center items-center gap-2.5 flex-wrap content-center group"
          >
            <div className="inline-flex flex-col justify-center items-center gap-2.5">
              <div
                className={`justify-start ${
                  getMenuItemStyles(isAdmin ? "/admin/dashboard" : "inspection")
                    .textColorClass
                } text-lg md:text-xl font-normal font-['Pretendard'] leading-loose transition-colors duration-200`}
              >
                {isAdmin ? "유저정보" : "검사결과"}
              </div>
              <div
                className={`self-stretch h-0.5 ${
                  isActive(isAdmin ? "/admin/dashboard" : "inspection")
                    ? "bg-blue-500"
                    : "bg-transparent group-hover:bg-blue-500"
                } transition-colors duration-200`}
              />
            </div>
          </Link>

          <Link
            to="/qna"
            className="py-3 mx-3 lg:mx-5 flex justify-center items-center gap-2.5 flex-wrap content-center group"
          >
            <div className="inline-flex flex-col justify-center items-center gap-2.5">
              <div
                className={`justify-start ${
                  getMenuItemStyles("/qna").textColorClass
                } text-lg md:text-xl font-normal font-['Pretendard'] leading-loose transition-colors duration-200`}
              >
                자주 묻는 질문
              </div>
              <div
                className={`self-stretch h-0.5 ${
                  isActive("/qna")
                    ? "bg-blue-500"
                    : "bg-transparent group-hover:bg-blue-500"
                } transition-colors duration-200`}
              />
            </div>
          </Link>
        </div>

        {/* User Profile/Login Section */}
        {accessToken || adminToken ? (
          <div className="mr-5 md:mr-[5%] lg:mr-[10%] xl:mr-[15%] flex justify-end items-center gap-3.5">
            <div className="text-right justify-start text-zinc-900 text-xl font-normal font-['Pretendard'] leading-loose">
              {adminName || username} 님
            </div>
            <div
              ref={userIconRef}
              onClick={handleToggleDropdown}
              className="w-7 h-7 bg-indigo-50 rounded-3xl flex justify-start items-center gap-2.5 cursor-pointer"
            >
              <div className="w-7 h-7 p-1 bg-indigo-50 rounded-3xl inline-flex justify-start items-center gap-2.5">
                <UserIcon className="w-5 h-5 " />
              </div>
            </div>

            {/* Portal을 사용한 드롭다운 메뉴 */}
            {showModal && portalElement && (
              <Dropdown onClose={toggleModal} position={dropdownPosition} />
            )}
          </div>
        ) : (
          <div className="mr-5 md:mr-[5%] lg:mr-[10%] xl:mr-[15%] text-xl text-gray-600">
            <Link
              className="py-3 flex justify-center items-center gap-2.5 flex-wrap content-center group"
              to="/signup"
            >
              <div className="inline-flex justify-center items-center gap-2">
                <div className="flex flex-col items-center">
                  <div className="justify-start text-zinc-900 hover:text-slate-500 text-xl font-medium font-['Pretendard'] leading-loose transition-colors duration-200">
                    회원가입
                  </div>
                </div>
                <div className="w-7 h-7 p-1 bg-stone-50 rounded-3xl outline outline-1 outline-offset-[-1px] outline-slate-500 hover:fill-gray-100 hover:outline hover:outline-1 hover:outline-offset-[-0.50px] hover:outline-gray-400  inline-flex justify-start items-center gap-2.5 transition-colors duration-200">
                  <GrayUserIcon className=" " />
                </div>
              </div>
            </Link>
          </div>
        )}
      </div>
    </header>
  );
};

export default GNB;
