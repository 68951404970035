import React, { useEffect } from "react";
import { motion } from "framer-motion";
import MainBannerImage1 from "@/assets/main banner-01.png";
import MainBannerImage2 from "@/assets/main banner-02.png";
import MainBannerImage3 from "@/assets/main banner-03.png";
import MainBannerImage4 from "@/assets/main banner-04.png";
import MainBannerImage5 from "@/assets/main banner-05.png";

const slides = [
  {
    id: 1,
    image: MainBannerImage1,
  },
  {
    id: 2,
    image: MainBannerImage2,
  },
  {
    id: 3,
    image: MainBannerImage3,
  },
  {
    id: 4,
    image: MainBannerImage4,
  },
  {
    id: 5,
    image: MainBannerImage5,
  },
];

const Carousel: React.FC = () => {
  const [currentIndex, setCurrentIndex] = React.useState(0);

  // 다음 슬라이드로 이동하는 함수
  const goToNextSlide = () => {
    const scrollY = window.scrollY;
    setCurrentIndex((prevIndex) =>
      prevIndex === slides.length - 1 ? 0 : prevIndex + 1
    );
    window.scrollTo(0, scrollY);
  };

  // 자동 슬라이드 변경
  useEffect(() => {
    const interval = setInterval(() => {
      goToNextSlide();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <motion.div
      key={currentIndex}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{
        duration: 0.5,
        ease: "circOut",
      }}
      className="w-[658px] h-[450px] relative rounded-lg overflow-hidden cursor-pointer"
      onClick={goToNextSlide}
    >
      {/* 텍스트와 이미지 */}

      <img className="w-full" src={slides[currentIndex].image} />
      {/* 슬라이드 인디케이터 */}
      <div className="absolute bottom-6 left-0 right-0 flex justify-center gap-3">
        {slides.map((_, index) => (
          <div
            key={index}
            className={`h-2 w-2 rounded-full ${
              index === currentIndex ? "bg-white" : "bg-white bg-opacity-50"
            }`}
          />
        ))}
      </div>
    </motion.div>
  );
};

export default Carousel;
