import React from "react";
import { motion, AnimatePresence } from "framer-motion";

interface Props {
  question: string;
  answer: string;
}
const QnaDropDown = ({ question, answer }: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="rounded-lg overflow-hidden border border-blue-500">
      <div
        className={`self-stretch px-9 py-8 bg-blue-50 inline-flex justify-between items-center w-full cursor-pointer ${
          isOpen ? "rounded-t-lg" : "rounded-lg"
        }`}
        onClick={toggleDropdown}
      >
        <div className="text-zinc-900 text-xl font-semibold font-['Pretendard'] leading-7">
          {question}
        </div>
        <motion.div
          initial={false}
          animate={{ rotate: isOpen ? 180 : 0 }}
          transition={{ duration: 0.3 }}
        >
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 11L14 18L21 11"
              stroke="#333333"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </motion.div>
      </div>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="overflow-hidden"
          >
            <div className="self-stretch pl-9 pr-9 md:pr-24 py-8 bg-white rounded-bl-lg rounded-br-lg">
              <div className="text-zinc-900 text-base font-normal font-['Pretendard'] leading-normal">
                {answer}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default QnaDropDown;
