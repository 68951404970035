import type { UpdateUserInfo, UserInfo } from "@/types/myinfo/types";
import { apiClient, apiProgress } from "../common/api";

export const getUserInfo = async (
  token: string,
  onProgress?: (arg: number) => void
): Promise<UserInfo | undefined> => {
  try {
    const { data } = await apiClient.get<UserInfo>("/my/info", {
      headers: {
        Authorization: token,
      },
      onDownloadProgress(progressEvent) {
        if (onProgress) apiProgress(progressEvent, onProgress);
      },
    });
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const updateUserInfo = async (token: string, data: UpdateUserInfo) => {
  try {
    const response = await apiClient.patch("/my/info/update", data, {
      headers: {
        Authorization: token,
      },
    });
    return response;
  } catch (err) {
    console.log(err);
  }
};
