import React from "react";
import DownloadIcon from "@/assets/download-icon.svg?react";
import { Link } from "react-router-dom";

interface InfoCardProps {
  icon?: string;
  link: string;
  title: string;
  subtitle: string;
  customIcon?: boolean;
}

const InfoCard: React.FC<InfoCardProps> = ({ icon, link, title, subtitle }) => {
  return (
    <div className="w-[320px] max-w-xl px-6 py-5 bg-white rounded-2xl shadow-all border border-shadow flex justify-between items-center">
      {/* 텍스트와 버튼 영역 */}
      <div className="flex flex-col justify-start items-start gap-6 flex-shrink-0">
        <div className="flex flex-col justify-start items-start gap-2">
          <div className="text-zinc-900 text-base font-semibold font-['Pretendard']">
            {title}
          </div>
          <div className="text-zinc-900 text-sm font-normal font-['Pretendard']">
            {subtitle}
          </div>
        </div>
        <Link
          to={link}
          className="px-4 py-2 bg-stone-50 rounded-md outline outline-1 outline-offset-[-1px] outline-zinc-200 flex justify-between items-center gap-1.5 hover:bg-stone-100 transition-colors"
        >
          <span className="text-zinc-900 text-sm font-normal font-['Pretendard']">
            다운로드
          </span>
          <span className="p-0.5 bg-neutral-300 rounded-[10px] flex justify-start items-center">
            <DownloadIcon className="w-3.5 h-3.5" />
          </span>
        </Link>
      </div>

      {/* 이미지 영역 */}
      <div className="w-24 h-24 flex-shrink-0 flex items-center justify-center">
        {icon && (
          <img
            src={icon}
            alt={title}
            className="w-[103px] h-[103px] object-contain"
          />
        )}
      </div>
    </div>
  );
};

export default InfoCard;
