import * as Sentry from "@sentry/react";

// Determine environment variables based on environment
const isDevelopment = process.env.NODE_ENV === "development";
const SENTRY_KEY = isDevelopment
  ? import.meta.env.VITE_SENTRY_KEY
  : process.env.SENTRY_KEY;
const CLIENT_API = isDevelopment
  ? import.meta.env.VITE_CLIENT_API
  : process.env.VITE_CLIENT_API;

Sentry.init({
  dsn: SENTRY_KEY,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", CLIENT_API],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
