import Carousel from "@/components/home/Carousel";
import InfoCard from "@/components/home/InfoCard";
import QuestionSlider from "@/components/home/QuestionSlider";
import MainPaperIcon from "@/assets/main_icon_paper.png";
import MainSheetIcon from "@/assets/main_icon_resultsheet.png";
import MainTalkIcon from "@/assets/main_icon_talk.png";
import LoginForm from "@/components/home/LoginForm";
import { useStorage } from "@/hooks/common/useStorage";
import InspectionSummary from "@/components/common/InspectionSummary";

const App = () => {
  const { accessToken } = useStorage();
  return (
    <div className="relative flex flex-col w-full gap-6 py-6">
      {/** 배너 */}
      <div className="w-full flex justify-center gap-9">
        {/* Main banner section */}
        <Carousel />
        {/* Login section */}
        {accessToken ? <InspectionSummary /> : <LoginForm />}
      </div>
      <div className="flex justify-center">
        <QuestionSlider />
      </div>

      <div className="flex justify-center items-center  gap-10">
        <InfoCard
          link="/"
          icon={MainPaperIcon}
          title="제품 카탈로그"
          subtitle="캔서뱃 제품 목록"
        />
        <InfoCard
          link="/"
          icon={MainSheetIcon}
          title="결과지 미리보기"
          subtitle="캔서뱃 결과지"
        />
        <InfoCard
          link="/"
          icon={MainTalkIcon}
          title="자주 묻는 질문"
          subtitle="QnA 모음"
        />
      </div>
    </div>
  );
};

export default App;
