import axios, { type AxiosProgressEvent } from "axios";

export const apiClient = axios.create({
  baseURL: import.meta.env.SERVER_API || import.meta.env.VITE_SERVER_API,
  headers: {
    "Content-Type": "application/json",
  },
});

export const apiProgress = (
  progressEvent: AxiosProgressEvent,
  onProgress: (progress: number) => void
) => {
  const total = progressEvent.total ?? 1;
  const current = progressEvent.loaded;

  if (progressEvent.total) {
    const progress = Math.round((current / total) * 100);
    onProgress(progress);
  } else {
    onProgress(0);
  }
};
