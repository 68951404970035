import LockIcon from "@/assets/lock-icon.svg?react";
import { useForm } from "react-hook-form";
import type { LoginDTO } from "@/types/login/types";
import useAuth from "@/hooks/common/useAuth";
import LoginButton from "./LoginButton";
import { Link } from "react-router-dom";

const LoginForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<LoginDTO>({
    mode: "onChange",
  });

  const { error, mutate } = useAuth({
    type: "accessToken",
  });

  return (
    <form
      onSubmit={handleSubmit((data) => mutate(data))}
      className="px-7 py-12 bg-white rounded-2xl shadow-all border border-shadow inline-flex flex-col justify-start items-start gap-9"
    >
      <div className="w-72 h-7 text-center justify-center text-zinc-900 text-2xl font-semibold font-['Pretendard']">
        로그인
      </div>
      <div className="self-stretch flex flex-col justify-start items-start gap-5">
        <div className="w-72 flex flex-col justify-start items-start gap-5">
          <div className="self-stretch flex flex-col justify-start items-start gap-2">
            <div className="flex flex-col w-full">
              <input
                type="text"
                id="loginId"
                placeholder="사업자등록번호"
                className={`self-stretch pl-5 pr-36 py-5 bg-white rounded-md outline outline-1 outline-offset-[-1px] inline-flex justify-start items-center gap-2.5 ${
                  errors.loginId ? "outline-red-600" : "outline-neutral-300"
                }`}
                {...register("loginId", {
                  required: "사업자 등록번호는 필수 항목입니다.",
                  validate: {
                    noHyphen: (value) =>
                      !value.includes("-") ||
                      "사업자 등록번호에 하이픈(-)을 포함할 수 없습니다.",
                    isTenDigits: (value) =>
                      value.length === 10 ||
                      "사업자 등록번호는 10자리여야 합니다.",
                  },
                })}
              />
              {errors.loginId && (
                <span className="error-message">{errors.loginId.message}</span>
              )}
            </div>
          </div>
          <div className="self-stretch flex flex-col justify-start items-start gap-2">
            <div className="flex flex-col w-full">
              <input
                type="password"
                id="password"
                placeholder="비밀번호"
                className={`self-stretch px-5 py-5 bg-white rounded-md outline outline-1 outline-offset-[-1px] inline-flex justify-start items-center gap-2.5 w-full ${
                  errors.password ? "outline-red-600" : "outline-neutral-300"
                }`}
                autoComplete="new-password"
                {...register("password", {
                  required: "비밀번호는 필수 항목입니다.",
                  validate: {
                    isTenDigits: (value) =>
                      value.length >= 10 ||
                      "비밀번호는 10자리 이상이어야 합니다.",
                  },
                })}
              />
              {errors.password && (
                <span className="error-message">{errors.password.message}</span>
              )}
            </div>
          </div>
        </div>
        <div className="w-72 flex flex-col justify-start items-start gap-4">
          <LoginButton isValid={isValid} error={error} />
          <div className="self-stretch inline-flex justify-start items-center gap-28">
            <div className="flex justify-start items-center gap-2">
              <LockIcon className="w-5 h-5" />
              <Link
                to={"/login/find"}
                className="w-20 justify-start text-slate-500 text-sm font-medium font-['Pretendard']"
              >
                비밀번호 찾기
              </Link>
            </div>
            <Link
              to={"/signup"}
              className="w-12 justify-start text-slate-500 text-sm font-medium font-['Pretendard'] whitespace-nowrap"
            >
              회원가입
            </Link>
          </div>
        </div>
      </div>
    </form>
  );
};

export default LoginForm;
