import { useState, useEffect } from "react";
import RightArrowIcon from "@/assets/Right_Arrow.svg?react";
import { motion, AnimatePresence } from "framer-motion";

const QuestionSlider = () => {
  const slides = [
    "검사 결과는 대략적으로 몇일 걸리는 건가요?",
    "CancerVet의 원리는 무엇인가요?",
    "암 검진은 몇 살 부터 받아야 하나요?",
    "양성 종양이나 악성 종양으로 예측된 경우에는 어떻게 하나요?",
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 4000);

    return () => clearInterval(interval);
  }, [slides.length]);

  return (
    <div className="w-[1036px] bg-white rounded-2xl shadow-all border border-shadow overflow-hidden px-12 py-[38px]">
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <div className="text-lg font-semibold mr-[60px]">자주 묻는 질문</div>
          <div className="h-10 min-w-[400px] relative flex items-center">
            <AnimatePresence mode="wait">
              <motion.div
                key={currentIndex}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{
                  duration: 0.5,
                  ease: "easeOut",
                }}
                className="absolute left-0"
              >
                {slides[currentIndex]}
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
        <div className="flex items-center">
          <span className="mr-3">더보기</span>
          <RightArrowIcon className="w-6 h-6" />
        </div>
      </div>
    </div>
  );
};

export default QuestionSlider;
