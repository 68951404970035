import { STORAGE_KEYS } from "@/constant/common/storage-keys";
import { useLocalStorage } from "usehooks-ts";

export const useStorage = () => {
  const [accessToken, setAccessToken] = useLocalStorage<string | null>(
    STORAGE_KEYS.ACCESS_TOKEN,
    null,
    {
      serializer: (value: string | null) => {
        if (value === null) {
          localStorage.removeItem(STORAGE_KEYS.ACCESS_TOKEN);
          return "";
        }
        return value;
      },
      deserializer: (value: string) => value || null,
    }
  );

  const [adminToken, setAdminToken] = useLocalStorage<string | null>(
    STORAGE_KEYS.ADMIN_ACCESS_TOKEN,
    null,
    {
      serializer: (value: string | null) => {
        if (value === null) {
          localStorage.removeItem(STORAGE_KEYS.ADMIN_ACCESS_TOKEN);
          return "";
        }
        return value;
      },
      deserializer: (value: string) => value || null,
    }
  );

  const [username, setUsername] = useLocalStorage<string | null>(
    STORAGE_KEYS.USERNAME,
    null,
    {
      serializer: (value: string | null) => {
        if (value === null) {
          localStorage.removeItem(STORAGE_KEYS.USERNAME);
          return "";
        }
        return value;
      },
      deserializer: (value: string) => value || null,
    }
  );

  const [adminName, setAdminName] = useLocalStorage<string | null>(
    STORAGE_KEYS.ADMIN_NAME,
    null,
    {
      serializer: (value: string | null) => {
        if (value === null) {
          localStorage.removeItem(STORAGE_KEYS.ADMIN_NAME);
          return "";
        }
        return value;
      },
      deserializer: (value: string) => value || null,
    }
  );

  const [bussinessNumber, setBussinessNumber] = useLocalStorage<string | null>(
    STORAGE_KEYS.BUSINESS_NUMBER,
    null,
    {
      serializer: (value: string | null) => {
        if (value === null) {
          localStorage.removeItem(STORAGE_KEYS.BUSINESS_NUMBER);
          return "";
        }
        return value;
      },
      deserializer: (value: string) => value || null,
    }
  );

  const clearUserData = () => {
    setAccessToken(null);
    setUsername(null);
    setBussinessNumber(null);
    localStorage.removeItem(STORAGE_KEYS.ACCESS_TOKEN);
    localStorage.removeItem(STORAGE_KEYS.USERNAME);
    localStorage.removeItem(STORAGE_KEYS.BUSINESS_NUMBER);
  };

  const clearAdminData = () => {
    setAdminToken(null);
    setAdminName(null);
    localStorage.removeItem(STORAGE_KEYS.ADMIN_ACCESS_TOKEN);
    localStorage.removeItem(STORAGE_KEYS.ADMIN_NAME);
  };

  const clearAll = () => {
    clearUserData();
    clearAdminData();
  };

  return {
    accessToken,
    setAccessToken,
    adminToken,
    setAdminToken,
    username,
    setUsername,
    adminName,
    setAdminName,
    bussinessNumber,
    setBussinessNumber,
    clearUserData,
    clearAdminData,
    clearAll,
  };
};
