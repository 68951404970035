import { useStorage } from "@/hooks/common/useStorage";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

interface DropdownMenuProps {
  onClose: () => void;
  position: {
    top: number;
    right: number;
  };
}

const Dropdown = ({ onClose, position }: DropdownMenuProps) => {
  const navigate = useNavigate();
  const { clearAll } = useStorage();
  const menuRef = useRef<HTMLDivElement>(null);

  const handleLogout = () => {
    clearAll();
    navigate("/");
    onClose();
  };

  const handleMyInfo = () => {
    navigate("/myinfo/update");
    onClose();
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div
      ref={menuRef}
      className="fixed bg-white border border-gray-200 rounded-lg shadow-lg z-[9999]"
      style={{
        top: `${position.top}px`,
        right: `${position.right}px`,
        width: "12rem",
        boxShadow:
          "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
      }}
    >
      <ul className="py-2">
        <li
          className="px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
          onClick={handleLogout}
        >
          로그아웃
        </li>
        <li
          className="px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
          onClick={handleMyInfo}
        >
          정보수정
        </li>
      </ul>
    </div>
  );
};

export default Dropdown;
